import React, { Fragment } from 'react';
import styles from './hero.module.scss';
import Button from '@components/Button';
import CardWithImage from '@components/CardWithImage';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import Img from 'gatsby-image';
import SVG from 'react-inlinesvg';
import useWindowDimensions from '@helpers/hooks/useWindowDimensions';

const Hero = ({
    title,
    text,
    bottomText,
    downloadButton,
    heroCards,
    processRef,
    heroCardsWrapper,
    heroBottomTitle,
    heroBottomDescription,
    heroBottomQuote,
}) => {
    const {width: windowWidth} = useWindowDimensions();
    const sm = 576;
    const isSm = windowWidth <= sm;

    return (
        <Fragment>
            <div className={styles.homeHeroWrapper}>
                <div className={styles.backgroundContainer}>
                    <SVG src="/images/home-hero-background.svg" alt="background" />
                    <div className={styles.heading}>
                        <h1>{title}</h1>
                        <p>{text}</p>

                        <div className={styles.headingLinks}>
                            <div>
                                <Button text="Find a PA" link="/contact"/>
                                <Button text="Become a PA" link="/become-a-pa"/>
                            </div>
                            <div>
                                <Button text="Uni Support" link="/going-to-uni"/>
                                <Button text="Success Story" link="/case-study"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.midgroundContainer}>
                    <img src="/images/midgroundConcert.svg" alt="midgroundConcert" />
                </div>
                <div className={styles.foregroundContainer}>
                    <img src="/images/foregroundConcert.svg" alt="foregroundConcert" />
                </div>
            </div>
            {downloadButton && (
                <div className={styles.content}>
                    <Grid>
                        <Row>
                            <Col md={6} xs={12}>
                                <h2>{heroBottomTitle}</h2>
                                <p>{heroBottomDescription}</p>
                                <b>{heroBottomQuote}</b>
                                {/*<div className={styles.downloadButton}>*/}
                                {/*    <Button text="Why LiveYou? - About Us" link="/about" />*/}
                                {/*</div>*/}
                            </Col>
                            <Col md={6} xs={12}>
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/x1ZkAL_aMss"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </Col>
                        </Row>
                    </Grid>
                </div>
            )}
        </Fragment>
    );
};

export default Hero;
