import React, { Fragment } from 'react';
import styles from './styles.module.scss';
import Button from '@components/Button';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

const CardWithImage = ({ image, title, text, buttonText, buttonLink, scroll, processRef }) => {
    return (
        <div className={styles.cardWithImage}>
            <Link to={buttonLink}>
            {!!scroll ? (
                <div
                    className={styles.cardWithRef}
                    onClick={() => processRef?.current?.scrollIntoView({ behavior: 'smooth' })}
                >
                    <div className={styles.cardHeader}>
                        <Img fluid={image} alt={title} />
                    </div>
                    <div className={styles.cardContent}>
                        <h2>{title}</h2>
                        <img src="/images/squiggle.svg" alt="a squiggle line" />
                        <p>{text}</p>
                        <Button
                            text={buttonText}
                            onClick={() =>
                                processRef?.current?.scrollIntoView({ behavior: 'smooth' })
                            }
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className={styles.cardHeader}>
                        <Img fluid={image} alt={title} />
                    </div>
                    <div className={styles.cardContent}>
                        <h2>{title}</h2>
                        <img src="/images/squiggle.svg" alt="a squiggle line" />
                        <p>{text}</p>
                        <Button text={buttonText} />
                    </div>
                </>
            )}
            </Link>
        </div>
    );
};

export default CardWithImage;
