import React, { Fragment } from 'react';
import styles from './styles.module.scss';
import Button from '@components/Button';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import { graphql, useStaticQuery } from 'gatsby';
import useWindowDimensions from '@helpers/hooks/useWindowDimensions';

const GoingToUniBanner = ({ displayButton = false }) => {
    const {width: windowWidth} = useWindowDimensions();
    const lg = 992
    const isLaptop = windowWidth >= lg;


    const {
        banner: {
            frontmatter: { title, description, graphic },
        },
    } = useStaticQuery(graphql`
        query UniBanner {
            banner: markdownRemark(fileAbsolutePath: { regex: "/going-to-uni/" }) {
                frontmatter {
                    title: uni_title
                    description: uni_description
                    graphic: uni_hero_graphic {
                        publicURL
                    }
                }
            }
        }
    `);

    return (
        <div className={styles.uniHeader}>
            {/*{!isLaptop && (*/}
            {/*    <div className={styles.graphicUniContainer}>*/}
            {/*        <img src={graphic?.publicURL} alt="lamp graphic" />*/}
            {/*    </div>*/}
            {/*)}*/}
            {/*<Grid>*/}
            {/*    <Row>*/}
            {/*        <Col lg={6} xs={12}>*/}
            {/*            <div>*/}
            {/*                {displayButton ? <h2>{title}</h2> : <h1>{title}</h1>}*/}
            {/*                <Divider />*/}
            {/*                <div*/}
            {/*                    className={styles.description}*/}
            {/*                    dangerouslySetInnerHTML={{*/}
            {/*                        __html: description,*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*                {displayButton && <Button text="Read more" link="/going-to-uni" />}*/}
            {/*            </div>*/}
            {/*        </Col>*/}
            {/*        {isLaptop && (*/}
            {/*            <Col lg={6} xs={12}>*/}
            {/*                <div className={styles.graphicUniContainer}>*/}
            {/*                    <img src={graphic?.publicURL} alt="lamp graphic" />*/}
            {/*                </div>*/}
            {/*            </Col>*/}
            {/*        )}*/}
            {/*    </Row>*/}
            {/*</Grid>*/}
            <Grid>
                <div className={styles.content}>
                    <div className={styles.contentContainer}>
                        {displayButton ? <h2>{title}</h2> : <h1>{title}</h1>}
                        <Divider />
                        <div
                            className={styles.description}
                            dangerouslySetInnerHTML={{
                                __html: description,
                            }}
                        />
                        {displayButton && <Button text="Read more" link="/going-to-uni" />}
                    </div>
                    <div className={styles.imageContainer}>
                        <img src={graphic?.publicURL} alt="lamp graphic" />
                    </div>
                </div>
            </Grid>
        </div>
    );
};

export default GoingToUniBanner;
