import React, { useRef } from 'react';
import styles from './styles.module.scss';
import Layout from '@components/Layout';
import Hero from '@components/Home/Hero';
import Process from '@components/Home/Process';
import Partners from '@components/Home/Partners';
import ContactSection from '@components/Home/ContactSection';
import { graphql } from 'gatsby';
import SEO from '@components/SEO';
import Button from '@components/Button';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import GoingToUniBanner from '@components/GoingToUniBanner';
import FreshApproach from "@components/FreshApproach";
import MainSection from "@components/MainSection";

const Home = ({
    data: {
        page: {
            frontmatter: {
                ourProcessItems,
                ourProcessTitle,
                ourProcessDescription,
                ourProcessImage,
                heroTitle,
                heroText,
                heroDescription,
                heroImage,
                ourMissionTitle,
                ourMissionDescription,
                ourMissionQuote,
                partnersTitle,
                partnersItems,
                contactTitle,
                heroCards,
                values
            },
        },
    },
}) => {
    const $process = useRef(null);
    const $contact = useRef(null);

    console.log(3, {values})
    return (
        <Layout>
            <SEO description="LiveYou is a user-led organisation which specialises in helping young adults with physical disabilities live independently and achieve their goals by creating bespoke personal assistant (PA) support packages for them" />
            <div className={styles.homePageWrapper}>
                <Hero
                    title={heroTitle}
                    text={heroText}
                    bottomText={heroDescription}
                    image={heroImage}
                    heroCards={heroCards}
                    processRef={$process}
                    contactRef={$contact}
                    heroBottomTitle={ourMissionTitle}
                    heroBottomDescription={ourMissionDescription}
                    heroBottomQuote={ourMissionQuote}
                    downloadButton
                />
                <FreshApproach values={values}/>

                <MainSection
                    title="Adam Turning The Tables"
                    subtitle="Success Story"
                    points={[
                        "Tripled Adam’s social care funding",
                        "Recruited 2 amazing PAs",
                        "Enabled Adam to launch a successful DJ career"
                    ]}
                    buttonText="Discover Adam's story"
                    buttonUrl="/case-study"
                />

                <Divider />
                <Divider />

                <Partners title={partnersTitle} partners={partnersItems} />

                <Divider margin={4} />
                <Divider margin={4} />
                <Divider margin={4} />
                <Divider margin={4} />


                <MainSection
                    title="Take the next step"
                    subtitle="Going to university?"
                    text="University will be your first taste of freedom and independence. To make the most of every minute, LiveYou sets you up with an awesome team of PAs so you can work hard and play harder."
                    buttonText="Learn about uni support"
                    buttonUrl="/going-to-uni"
                    inversed
                />

                <GoingToUniBanner displayButton />

                <Divider />
                <Divider />

                <Grid>
                    <Divider margin={4} />
                    <Divider margin={4} />
                    <Divider margin={4} />
                    <ContactSection title={contactTitle} ref={$contact} />
                </Grid>
            </div>

            <Divider />
            <Divider />
        </Layout>
    );
};

export default Home;

export const query = graphql`
    query homePage($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                heroTitle: home_hero_title
                heroImage: home_hero_image {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                heroText: home_hero_text
                heroDescription: home_hero_description
                heroCards: home_hero_cards {
                    title: home_hero_cards_card_title
                    text: home_hero_cards_card_text
                    link: home_hero_cards_card_link
                    scroll: home_hero_card_card_scroll_link
                    linkText: home_hero_cards_card_link_text
                    image: home_hero_cards_card_image {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                ourProcessItems: home_our_process_items {
                    text: home_our_process_items_item_text
                    image: home_our_process_items_item_image {
                        publicURL
                    }
                    title: home_our_process_items_item_title
                }
                ourProcessImage: home_our_process_image {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                values: home_values {
                    title: home_values_value_title
                    text: home_values_value_text
                    image: home_values_value_image {
                        publicURL
                    }
                }
                ourProcessDescription: home_our_process_description
                ourProcessTitle: home_our_process_title
                ourMissionTitle: our_mission_title
                ourMissionDescription: our_mission_description
                ourMissionQuote: our_mission_quote
                partnersTitle: home_partners_title
                partnersItems: home_partners_items {
                    logo: home_partners_items_logo {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    link: home_partners_items_link
                    title: home_partners_items_title
                    description: home_partners_items_description
                }
                contactTitle: home_contact_title
            }
        }
    }
`;
