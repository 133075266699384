import React, {Fragment} from "react";
import {Grid, Row, Divider, Col} from '@element-softworks/daintree';
import styles from './styles.module.scss';
import Button from '@components/Button';

const MainSection = ({title, subtitle, points, image, text, buttonText, buttonUrl, inversed}) => {
	return <div className={styles.sectionContainer}>
		<Grid>
			<Row>
				{inversed && <Col xs={12} md={6}>
					<div className={styles.sectionImage}/>
				</Col>}
				<Col xs={12} md={6}>
					<div className={styles.sectionHeading}>
						<h3>{subtitle}</h3>
						<h4>{title}</h4>
						<ul>
							{!!points && points?.map((point, index) => (
								<li key={index}>{point}</li>
							))}
						</ul>
						<div>
							{!!text && text}
						</div>


						{buttonUrl && <Button text={buttonText ?? "Learn more"} to={buttonUrl} />}
					</div>

				</Col>
				{!inversed && <Col xs={12} md={6}>
					<div className={styles.sectionImage}/>
				</Col>}
			</Row>

		</Grid>
	</div>
};

export default MainSection;
