import React, {useEffect, useRef, useState, useLayoutEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './partners.module.scss';
import Img from 'gatsby-image';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from '@components/Button';
import Slider from "react-slick";
import useWindowDimensions from '@helpers/hooks/useWindowDimensions';

const Partners = ({title, partners}) => {
	const {width: windowWidth} = useWindowDimensions();
	const lg = 992;
	const $sliderRef = React.useRef(null);
	const $sliderContainer = React.useRef(null);
	const [sliderState, setSliderState] = useState({
		prevIndex: 0,
		activeIndex: windowWidth < lg ? 0 : 1,
	});
	const [activePartner, setActivePartner] = useState(partners?.[sliderState?.activeIndex]);


	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: windowWidth < lg ? 1 : 3,
		centerMode: windowWidth >= lg,
		slidesToScroll: 1,
		lazyLoad: false,
		className: 'slick-slider-custom',
		initialSlide: windowWidth < lg ? 0 : 1,
		beforeChange: (prevIndex, newIndex) => {
			setSliderState({prevIndex: prevIndex, activeIndex: newIndex});
			setActivePartner(partners?.[newIndex])
		},
	}


	return (
		<div>
			<h2 className={styles.partnerTitle}>{title}</h2>
			<div ref={$sliderContainer} className={styles.sliderContainer}>
				<Slider ref={$sliderRef} {...settings}>
					{partners?.map(({title, logo}, index) => (
						<div className={styles.sliderImageContainer} key={index}>
							<div className={styles.container}>
								<Img
									fluid={logo?.childImageSharp?.fluid}
									alt={title}
									imgStyle={{objectFit: 'contain'}}
									loading={'eager'}
								/>
							</div>
						</div>
					))}
				</Slider>
				{!!activePartner && (
					<div className={styles.partnerContent}>
						<div className={styles.partnerContentTitle}>
							<div>
								<FontAwesomeIcon
									icon={['far', 'long-arrow-left']}
									onClick={() => $sliderRef?.current?.slickPrev()}
								/>
							</div>
							<h3>{activePartner?.title}</h3>
							<div>
								<FontAwesomeIcon
									icon={['far', 'long-arrow-right']}
									onClick={() => $sliderRef?.current?.slickNext()}
								/>
							</div>
						</div>
						<img src="/images/squiggle.svg" alt="a squiggle line"/>
						<p>{activePartner?.description}</p>
						<Button text={`Visit ${activePartner?.title}`} link={activePartner?.link}/>
					</div>
				)}
			</div>
		</div>
	);
};

export default Partners;
